.WebNavbar {
  min-height: 50px;
  max-height: 80px;
  width: 100%; 
  height: 90px;
  position: fixed;
  
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(12, 108, 164, 0.7);
}

/* HomeLink */
.WebNavbar-HomeLink {
  float: left;
  height: 100%;
  padding: 8px;

  background-color: #108BD2;

  text-align: center;
  text-decoration: none;

  transition: 0.25s;
}

.WebNavbar-HomeLink:hover {
  background-color: #2EA9EF;
}

.WebNavbar-HomeLink-Logo {
   height: inherit;
}

/* Item */
.WebNavbar-Item {
  float: left;
  overflow: hidden;
  height: 100%;
}

.WebNavbar-Item-Button {
  padding: 14px 16px;
  margin: 0;
  height: inherit;
  z-index: 2; /* So that WebNavbar-Item-Subitem slides under it */
  min-width: 140px;
  position: relative;

  font-size: 1.1em;
  border: none;
  outline: none;
  color: #000;
  background-color: #fff;
  font-family: inherit;

  transition: 0.35s;
}

.WebNavbar-Item-Button-Arrow {
  font-size: 0.7em;
}

.WebNavbar-Item-Button-Underline {
  position: relative;
  height: 5px;
  left:0;
  right:0;
  bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
  z-index: 2;

  background-color: #F39862;

  transition: 0.25s;
}

.WebNavbar-Item-Subitem {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  min-width: 140px;
  
  background-color: #108BD2;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.7);

  opacity: 0;
  top: 0px;
  transition: 0.25s;
}

.WebNavbar-Item-Subitem-Link {
  float: none;
  display: block;
  padding: 14px 16px;

  font-size: 1em;
  color: white;
  text-align: center;
  text-decoration: none;
}

/* Hover */

.WebNavbar-Item:hover .WebNavbar-Item-Button-Underline {
  height: 10px;
  bottom: 10px;

  background-color: #D30F8B;
}

.WebNavbar-Item:hover .WebNavbar-Item-Subitem {
  visibility: visible;

  top: 100%;
  opacity: 1;
}

.WebNavbar-Item:hover .WebNavbar-Item-Button {
  background-color: #108BD2;
  color: #fff;
  font-weight: bold;
}

.WebNavbar-Item-Subitem a:hover {
  background-color: #D30F8B;
  font-weight: bold;
}

/* Highlight */

.WebNavbar-Item.Highlight {
  color: white;
  background-color: #F022A4;
}
