.MobileNavbar {
  width: 100%; 
  height: 50px;
  position: fixed;

  text-align: center;
  background: #0C6CA4;
  background: linear-gradient(180deg, #0C6CA4 0%, #108BD2 30%, #108BD2 70%, #0C6CA4 100%);
  box-shadow: 0px 8px 16px 0px rgba(12, 108, 164, 0.7);
}


/* HomeLink */
.MobileNavbar-HomeLink {
  height: 100%;

  text-decoration: none;
}

.MobileNavbar-HomeLink-Logo {
   height: inherit;
}

/* Menu Button */

.MobileNavbar-MenuButton {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 100%;

  border: none;
  outline: none;
  text-align: center;
  background: linear-gradient(180deg, #0C6CA4 0%, #108BD2 30%, #108BD2 70%, #0C6CA4 100%);
}

.MobileNavbar-MenuButton-Icon {
  padding: 5px;  
}

.MobileNavbar-MenuButton-Icon-Bar {
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background-color: #fff;
  margin: 6px 0;
}


/* Menu Button */

.MobileNavbar-SideNav {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 2;
  top: 50px;
  left: 0;

  background-color: #111;
  overflow-x: hidden;
  
  transition: 0.5s;
}

.MobileNavbar-DarkOverlay {
  height: 100%;
  width: 100vw;
  position: fixed;
  z-index: 1;
  top: 50px;
  left: 0;
  
  background-color: #0000009f;
  border: none;
  outline: none;
}

.MobileNavbar-SideNav-Item {
  overflow: hidden;
  width: 100%;
}

.MobileNavbar-SideNav-Item-Button {
  padding: 14px 16px;
  margin: 0;
  width: inherit;
  max-height: 60px;

  font-size: 1.1em;
  border: none;
  outline: none;
  color: white;
  background-color: #108BD2;
  font-family: inherit;

  transition: 0.35s;
}

.MobileNavbar-SideNav-Item-Button-Arrow {
  font-size: 0.7em;
}

.MobileNavbar-Item-Button-Underline {
  position: absolute;
  z-index: 3;

  height: 48px;
  width: 8px;

  background-color: #A60C6E;

  transition: width 0.35s;
}

.MobileNavbar-SideNav-Item-Subitem {
  visibility: hidden;
  position: relative;
  z-index: 3;
  
  background-color: #2EA9EF;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.7);

  height: 0;
  transition: 0.35s;
}

.MobileNavbar-SideNav-Item-Subitem-Link {
  float: none;
  display: block;
  padding: 14px 16px;

  font-size: 1em;
  color: white;
  text-align: center;
  text-decoration: none;
}

/* Clicked */


.MobileNavbar-Item-Button-Underline.Clicked {
  width: 16px;

  transition: width 0.35s;
}

.MobileNavbar-SideNav-Item.Clicked .MobileNavbar-SideNav-Item-Subitem {
  visibility: visible;

  height: 98px; /* TODO: this number needs to be adjusted if there are more or less than 2 item... */

  /* Got to figure out a way to only change the color if it is a highlighted parent... */
  /* background-color: #F46791; */
}

/* .MobileNavbar-SideNav-Item.Clicked .MobileNavbar-SideNav-Item-Button {
  background-color: #ff0000;
  font-weight: bold;
}

.MobileNavbar-SideNav-Item-Subitem .MobileNavbar-SideNav-Item-Subitem-Link {
  background-color: #ff4040;
  font-weight: bold;
} */

/* Highlight */

.MobileNavbar-SideNav-Item-Button.Highlight {
  /* background-color: #A60C6E; */
}
