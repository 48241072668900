.Navbar {
  width: 100%;
}

/* Media queries */

@media (max-width: 840px) {
  .WebNavbar {
    display: none;
  }
}
  
@media (min-width: 840px) {
  .MobileNavbar {
    display: none;
  }
}
