* {
  box-sizing: border-box;
}

html {
  position: relative; /* Hack to stretch out the absolute positioned background vertically. See .App-bg */
}

body {
  background-color: #39ADF0;

  color: #fff;
  font-family: 'Comic Neue', cursive;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center; /* cross axis */
  justify-content: center; /* main axis */
}

/* Background */
.App-bg {
  width: 100%;
  height: 100%;

  background-repeat: repeat;
  opacity: 40%;

  position: absolute;
  z-index: -1;
}

@media (max-width: 840px) {
  .App-bg {
    background-size: 172px 172px;
  }
}
  
@media (min-width: 840px) {
  .App-bg {
    background-size: 320px 320px;
  }
}


/* Header */

.App-header {
  margin-bottom: 20px;
}

@media (max-width: 840px) {
  .App-header {
    height: 50px;
  }
}
  
@media (min-width: 840px) {
  .App-header {
    height: 90px;
  }
}


/* Body */

.App-body {
  width: min(100%, 1200px);
  max-width: min(100%, 1200px); /* for the inner image (or other wide content) */

  color: #000;

  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 840px) {
  .App-body {
    min-height: calc(100vh - 50px - 20px - 31px); /* 100 - navbar - header - footer */
  }
}
  
@media (min-width: 840px) {
  .App-body {
    min-height: calc(100vh - 50px - 20px - 40px - 31px); /* 100 - navbar - header - margins - footer */
  }
}


/* Content */

/* 
The iframe used for youtube videos is not so smart when it comes to fully filling the content's width. 
So here we give it a little help to figugre things out.
*/ 
.iframe-width-helper {
  width: -webkit-fill-available;
}

.App-body-content {
  max-width: inherit; /* For mobile content fit */

  background-color: white;
  margin: 16px;
  border-radius: 32px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: normal;
}

.App-body-content-title {
  font-size: 2em;

  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;

  /* text-shadow: 1px 2px #000; */
}

@media (max-width: 840px) {
  .App-body-content-title {
    font-size: 2em;
  }

  .App-body-content-date {
    font-size: 1em;
  }
}
  
@media (min-width: 840px) {
  .App-body-content-title {
    font-size: 3em;
  }

  .App-body-content-date {
    font-size: 1.5em;
  }
}

.App-body-content-splitter, .App-body-archive-splitter {
  width: 100%;
  margin-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.App-body-content-splitter-inner, .App-body-archive-splitter-inner {
  width: 100%;
  height: 2px;

  border-radius: 10px;
  background-color: #666;
}

.App-body-content-image {
   /* For mobile content fit */
  max-height: inherit;
  max-width: inherit;

  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.7);
}


/* Using media sizes as a hack to make videos have an acceptable iframe ratio size. */
/* Unfortunately using other methods result in the video being layered on top of the menus. */
@media (max-width: 420px) {
  .App-body-content-video {
    height: 240px;
  }
}

@media (min-width: 420px) and (max-width: 600px) {
  .App-body-content-video {
    height: 450px;
  }
}
  
@media (min-width: 600px) and (max-width: 840px) {
  .App-body-content-video {
    height: 630px;
  }
}
  
@media (min-width: 840px) and (max-width: 1200px) {
  .App-body-content-video {
    height: 675px;
  }
}
  
@media (min-width: 1200px) {
  .App-body-content-video {
    height: 675px;
  }
}

.App-body-content-video {
  
}

.App-body-content-video-iframe {
  width: 100%;
  height: 100%;

  border: 0px;

  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.7);
}


/* Archive */

.App-body-archive {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: normal;

  background-color: white;
  margin: 16px;
  border-radius: 32px;
}

.App-body-archive-title {
  margin-top: 1rem;
}

@media (max-width: 840px) {
  .App-body-archive-title {
    font-size: 2em;
  }
}
  
@media (min-width: 840px) {
  .App-body-archive-title {
    font-size: 3em;
  }
}

.App-body-archive-images {
  width: min(100%, 1200px);

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  /* display: flex;
  flex-wrap: wrap;
  align-content: space-between; */
}

.App-body-archive-images-link-image {
  margin: 0.5rem;

  /* transition: 0.5s; */ /* Makes things go a bit funky... not sure if funky good or funky bad... */

  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.7);
}

.App-body-archive-images-link-image:hover {
  margin: 0.1rem;
  border: #F36762 0.4rem solid;
}


/* Content Navigation */

.App-body-archive-buttons,
.App-body-content-buttons {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 840px) {
  .App-body-archive-buttons,
  .App-body-content-buttons {
    width: min(100%, 360px);

    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 360px) {
  .App-body-archive-buttons,
  .App-body-content-buttons {
    width: min(100%, 360px);

    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
  
@media (min-width: 840px) {
  .App-body-archive-buttons,
  .App-body-content-buttons {
    width: min(100%, 620px);

    margin-top: 1.0rem;
    margin-bottom: 1.0rem;
  }
}

.App-body-archive-buttons-button,
.App-body-content-buttons-button {
  margin: 0.5rem;
 
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  color: #F39862;
  border-radius: 50%;
  border-color: #F39862;
  border-width: 4px;
  border-style: solid;

  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.7);

  text-decoration: none; /* No anchor link underline */
  user-select: none; /* To prevent the fake arrow characters from being selectable */
}


@media (max-width: 840px) {
  .App-body-archive-buttons-button,
  .App-body-content-buttons-button {
    height: 4rem;
    width: 4rem;

    padding-right: 0.1rem;
    padding-bottom: 0.3rem;

    font-size: 3em;
  }
}

@media (max-width: 360px) {
  .App-body-archive-buttons-button,
  .App-body-content-buttons-button {
    height: 3rem;
    width: 3rem;

    padding-right: 0.1rem;
    padding-bottom: 0.3rem;

    font-size: 2em;
  }
}
  
@media (min-width: 840px) {
  .App-body-archive-buttons-button,
  .App-body-content-buttons-button {
    height: 5rem;
    width: 5rem;

    padding-bottom: 0.5rem;

    font-size: 4em;
  }
}

.App-body-archive-buttons-button:hover,
.App-body-content-buttons-button:hover {
  background-color: #f7f099;
  color: #F36762;
  border-color: #F36762;
}

.App-body-archive-buttons-button:active,
.App-body-content-buttons-button:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);

  background-color: #f8ed53;
  color: #ff4545;
}

.App-body-archive-buttons-button-disabled,
.App-body-content-buttons-button-disabled {
  margin: 0.5rem;
  padding-bottom: 0.5rem;
 
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 2em;
  background-color: #cccccc;
  color: #3f3f3f;
  border-radius: 50%;

  pointer-events: none;

  text-decoration: none; /* No anchor link underline */
  user-select: none; /* To prevent the fake arrow characters from being selectable */
}

@media (max-width: 840px) {
  .App-body-archive-buttons-button-disabled,
  .App-body-content-buttons-button-disabled {
    height: 4rem;
    width: 4rem;

    padding-right: 0.1rem;
    padding-bottom: 0.3rem;

    font-size: 3em;
  }
}

@media (max-width: 360px) {
  .App-body-archive-buttons-button-disabled,
  .App-body-content-buttons-button-disabled {
    height: 3rem;
    width: 3rem;

    padding-right: 0.1rem;
    padding-bottom: 0.3rem;

    font-size: 2em;
  }
}
  

@media (min-width: 840px) {
  .App-body-archive-buttons-button-disabled,
  .App-body-content-buttons-button-disabled {
    height: 5rem;
    width: 5rem;

    padding-bottom: 0.5rem;

    font-size: 4em;
  }
}


/* Footer */

.App-footer {
  height: 31px;
  width: 100%;

  background-color: #0C6CA4;
  box-shadow: 0px 8px 16px 8px rgba(0,0,0,0.7);

  font-size: 1rem;
  color: white;

  display: flex;
}

.App-footer-image {
  border-width: 0;
}

.App-footer-text {
  line-height: 31px;

  margin-left: 10px;
  vertical-align: middle;
}

.App-footer-text-link {
  text-decoration: underline;
}

/* unvisited link */
.App-footer-text-link:link {
  color: #ccc;
}

/* visited link */
.App-footer-text-link:visited {
  color: #aaa;
}

/* mouse over link */
.App-footer-text-link:hover {
  color: #ddd;
}

/* selected link */
.App-footer-text-link:active {
  color: #fff;
}

@media (max-width: 840px) {
  .App-footer-long-text {
    display: none;
  }
}

@media (min-width: 840px) {
  .App-footer-short-text {
    display: none;
  }
}
